import { Flex } from "components/LEGACY/styled-components/Flex"
import EmailLoginForm from "components/utils/EmailLoginForm"
import PatternPageContainer from "components/utils/PatternPageContainer"
import SocialLoginButtons from "components/utils/SocialLoginButtons"
import VerticalDivider from "components/utils/VerticalDivider"
import getpackedLogo from "images/getpacked_Logo_schwarz.svg"
import Image from "next/image"
import Link from "next/link"
import { useMemo } from "react"

const decodeBase64 = (s: string) => {
  let i,
    b = 0,
    c,
    x,
    l = 0,
    a,
    r = ""
  const A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/",
    e: Record<string, number> = {},
    w = String.fromCharCode,
    L = s.length
  for (i = 0; i < 64; i++) {
    e[A.charAt(i)] = i
  }
  for (x = 0; x < L; x++) {
    c = e[s.charAt(x)]
    b = (b << 6) + c
    l += 6
    while (l >= 8) ((a = (b >>> (l -= 8)) & 0xff) || x < L - 2) && (r += w(a))
  }
  return r
}

const LoginPage = () => {
  const location = typeof window !== "undefined" ? window.location : undefined

  const loginString = location?.search.startsWith("?login=") ? location?.search.slice(7) : undefined
  const [encodedEmail, encodedPassword] = useMemo(() => {
    const decodedLoginString = loginString && decodeBase64(loginString)
    return decodedLoginString?.split(":") || [undefined, undefined]
  }, [loginString])

  return (
    <>
      <style jsx>{`
        .container {
          display: grid;
          justify-items: center;
          position: relative;
          grid-gap: 10px;
        }
        .linkContainer {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0 10px;
        }
      `}</style>
      <PatternPageContainer light>
        <Flex style={{ height: "100%" }} justifyCenter alignCenter>
          <div className="container">
            <Image src={getpackedLogo} alt={"getpacked logo"} layout="fixed" />

            <h1 style={{ color: "black" }}>Bitte melde dich an</h1>
            <EmailLoginForm defaultEmail={encodedEmail} defaultPassword={encodedPassword} trySigninWithDefaults />
            {/* <Link href="/forgot-password">Passwort vergessen?</Link> */}
            <div className="linkContainer">
              <Link href="/signup">Account erstellen</Link>
              <Link href="/reset-password">Passwort vergessen?</Link>
            </div>

            <VerticalDivider>oder</VerticalDivider>
            <SocialLoginButtons />

            <hr />
          </div>
        </Flex>
      </PatternPageContainer>
    </>
  )
}

export default LoginPage
